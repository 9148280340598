import { NotificationProvider } from 'modules/Notification/Context';
import NotificationCenter from 'modules/Notification/Notification';
import React from 'react';

export const wrapRootElement = ({ element }) => (
  <NotificationProvider>
    <NotificationCenter />
    {element}
  </NotificationProvider>
);
